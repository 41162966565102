import { postRequest, getRequest } from '@/libs/axios';


//分销列表
export const getListConfig = (params) => {
    return postRequest('/api/user-brokerage/spread/list', params)
}
//添加分销员
export const addDistributor = (params) => {
    return getRequest('/api/user-brokerage/add/spread/' + params.id, params)
}
//删除分销员
export const delDistributor = (params) => {
    return getRequest('/api/user-brokerage/del/spread/' + params.id, params)
}
//添加上级推广人
export const addSuperiors = (params) => {
    return getRequest('/api/user-brokerage/add/parent-spread/' + params.id, params)
}
//清除上级推广人
export const cleanSuperiors = (params) => {
    return getRequest('/api/user-brokerage/del/parent-spread/' + params.id, params)
}
//推广佣金明细
export const getSpreadMsg = (params) => {
    return postRequest('/api/user-brokerage/spread/page', params)
}




