<!-- 分销管理页面 -->
<template>
  <div class="distributionManagement">
    <div class="top-area clr">
      <Button type="primary">新增</Button>
      <Input
        v-model="searchText"
        @on-search="search"
        style="width:240px"
        class="fr"
        search
        placeholder="请输入..."
      />
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="promoterFlag">
          <span>{{row.promoterFlag?'是':'否'}}</span>
        </template>
        <template slot-scope="{ row }" slot="action">
          <div>
            <a @click="addSuperiors(row)">新增上级推广人</a>
          </div>
          <div>
            <a style="color:red;" @click="cleanSuperiors(row)">清除上级推广人</a>
          </div>
          <div>
            <a style="color:red;" @click="remove(row)">删除</a>
          </div>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getListConfig, delDistributor, cleanSuperiors, getSpreadMsg } from '@/api/commissionConfig.js'

export default {
  data() {
    return {
      searchText: '',
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '用户id',
          key: 'uid'
        },
        {
          title: '用户昵称',
          key: 'nickname',
        },
        {
          title: '手机号码',
          key: 'mobile'
        },
        {
          title: '是否为分销推广员',
          key: 'promoterFlag',
          slot: 'promoterFlag'
        },
        {
          title: '上级推广人名称',
          key: 'parentName',
        },
        {
          title: '推广用户数量',
          key: 'spreadCount'
        },
        {
          title: '推广订单数量',
          key: 'spreadOrderNum'
        },
        {
          title: '推广订单金额',
          key: 'spreadOrderTotalPrice'
        },
        {
          title: '佣金总金额',
          key: 'totalBrokeragePrice'
        },
        {
          title: '操作',
          slot: 'action',
          width: 150
        },
      ],
      dataList: []
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      getListConfig({
        pageIndex: this.current,
        size: this.size,
        nickName: this.searchText
      }).then(res => {
        if (res.success) {
          this.dataList = res.result.list || []
          this.total = res.result.total
        }
      })
    },
    remove(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          delDistributor({ id: row.uid }).then(res => {
            if (res.success) {
              this.$Message.success('删除成功！')
              this.findPageDataList()
            }
          });
        },
      })
    },
    edit(row) {
      console.log(row)
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    getSpreadMsg(row) {
      getSpreadMsg({ id: row.uid }).then(res => {
        if (res.success) {
          console.log(res)
        }
      })
    },
    cleanSuperiors(row) {
      cleanSuperiors({ id: row.uid }).then(res => {
        if (res.success) {
          this.findPageDataList()
        }
      })
    },
  }
}

</script>
<style lang='scss' scoped>
.distributionManagement {
}
</style>